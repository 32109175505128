import React, { PureComponent } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Markup } from 'interweave';

// custom imports
import * as CONSTANTS from '../constants';
import * as TEXT from './staticResources/text';
import ShareButtonList from './reusable/ShareButtonList';

import { getTitle, getApplicationUrl } from '../utils';

const THEME = require('../theme');

class JobPostingDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      jobPostingItem: {
        projectNumber: '',
        jobPosting: {},
        brand: ''
      }
    };
  }

  getJobPosting() {
    const url = CONSTANTS.jobPostingURL;
    const payload = { projectId: this.props.projectId };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(payload),
      timeout: 30000
    })
      .done((responseBody, status) => {
        console.log('API /getJobPosting response', responseBody, status);
        this.setState({ jobPostingItem: responseBody });
      })
      .fail(err => {
        console.log('Error handling required', err);
      });
  }

  componentDidMount() {
    this.getJobPosting();
    if (CONSTANTS.isMatchflix) ReactGA.pageview(window.location.pathname + window.location.search);
  }

  /**
   * renderBanner is responsible for rendering the banner image of each companys banner
   * here i am using 2 images in each if because the CardMedia is for the normal view and
   * the img is used only for the print view, because background-images arent shown in print view
   */
  renderBanner() {
    const imageStyles = { width: '100%', maxHeight: '12vh', objectFit: 'cover', marginBottom: '5vh' };

    if (CONSTANTS.isMatchflix)
      return (
        <>
          <div className="job-posting-detail__header-image">
            <CardMedia style={{ height: 0, paddingTop: '21%' }} image="/img/mf-banner.png" title="Matchflix Banner" />
          </div>
          <img style={imageStyles} src="/img/mf-banner.png" alt="Matchflix Banner" className="print-only" />
        </>
      );
    if (CONSTANTS.isSenator) {
      return (
        <>
          <div className="job-posting-detail__header-image">
            <CardMedia style={{ height: 0, paddingTop: '21%' }} image="/img/senator-banner.png" title="Senator Banner" />
          </div>
          <img style={imageStyles} src="/img/senator-banner.png" alt="Senator Banner" className="print-only" />
        </>
      );
    }
    if (CONSTANTS.isHrcg && this.state.jobPostingItem.brand === 'personaltotal') {
      return (
        <>
          <div className="job-posting-detail__header-image">
            <CardMedia style={{ height: 0, paddingTop: '21%' }} image="/img/pt-banner.jpg" title="Personaltotal Banner" />
          </div>

          <img style={imageStyles} src="/img/pt-banner.jpg" alt="Personaltotal Banner" className="print-only" />
        </>
      );
    }
    if (CONSTANTS.isHrcg && this.state.jobPostingItem.brand === 'senator') {
      return (
        <>
          <div className="job-posting-detail__header-image">
            <CardMedia style={{ height: 0, paddingTop: '21%' }} image="/img/senator-banner.png" title="Senator Banner" />
          </div>
          <img style={imageStyles} src="/img/senator-banner.png" alt="Senator Banner" className="print-only" />
        </>
      );
    }
    if (CONSTANTS.isHrcg && this.state.jobPostingItem.brand === 'lienert') {
      return (
        <>
          <div className="job-posting-detail__header-image">
            <CardMedia
              style={{ height: 0, backgroundSize: 'cover', paddingTop: '13%' }}
              image="/img/lienert_banner.jpg"
              title="Lienert Banner"
            />
          </div>
          <img style={imageStyles} src="/img/lienert_banner.jpg" alt="Lienert Banner" className="print-only" />
        </>
      );
    }
    return '';
  }

  render() {
    const buttonColor = this.state.jobPostingItem.brand ? THEME[this.state.jobPostingItem.brand].baseColor : '#3f50b5';
    const applicationLink = `${getApplicationUrl(this.state.jobPostingItem.brand)}${this.state.jobPostingItem.projectNumber}`;

    return (
      <div className="container mt-5 mb-5">
        <Card>
          {this.renderBanner()}
          <CardActions>
            <div className="hide-on-print">
              <Link to="/">
                <Button size="small">
                  {this.state.jobPostingItem.jobPosting.isInEnglish
                    ? TEXT.buttonDescriptor.jobPostingDetailsEnglish
                    : TEXT.buttonDescriptor.jobPostingDetails}
                </Button>
              </Link>
            </div>
          </CardActions>
          <CardContent className="ctm-heading-formatting">
            <h1 className="jobposting-main-title">{this.state.jobPostingItem.jobPosting.title}</h1>
            <h6>{this.state.jobPostingItem.jobPosting.location}</h6>
            <h2>{this.state.jobPostingItem.jobPosting.isInEnglish ? 'The company' : 'Das Unternehmen'}</h2>
            <Markup className="rend-component" content={this.state.jobPostingItem.jobPosting.description} />
            <h2>{this.state.jobPostingItem.jobPosting.isInEnglish ? 'The position' : 'Die Position'}</h2>
            <Markup className="rend-component" content={this.state.jobPostingItem.jobPosting.tasks} />
            <h2>{this.state.jobPostingItem.jobPosting.isInEnglish ? 'Your Profile' : 'Ihr Profil'}</h2>
            <Markup className="rend-component" content={this.state.jobPostingItem.jobPosting.profile} />
            <h2>{this.state.jobPostingItem.jobPosting.isInEnglish ? 'Benefits' : 'Benefits'}</h2>
            <Markup className="rend-component" content={this.state.jobPostingItem.jobPosting.offer} />
            <h2>{this.state.jobPostingItem.jobPosting.isInEnglish ? 'Contact' : 'Kontakt'}</h2>
            <Markup className="rend-component" content={this.state.jobPostingItem.jobPosting.contactinfo} />

            <div className="hide-on-print">
              <ShareButtonList
                size="40px"
                url={window.location.href}
                jobTitle={this.state.jobPostingItem.jobPosting.title}
                jobBrand={getTitle(this.state.jobPostingItem.brand)}
              />
            </div>
          </CardContent>
          <CardActions className="d-flex flex-row justify-content-between hide-on-print">
            <div>
              <Link to="/">
                <Button size="small">
                  {this.state.jobPostingItem.jobPosting.isInEnglish
                    ? TEXT.buttonDescriptor.jobPostingDetailsEnglish
                    : TEXT.buttonDescriptor.jobPostingDetails}
                </Button>
              </Link>
            </div>
            {this.state.jobPostingItem.brand !== 'lienert' && (
              <div>
                <a href={applicationLink} style={{ textDecoration: 'none' }}>
                  <Button size="large" style={{ background: buttonColor, color: '#ffffff' }}>
                    {this.state.jobPostingItem.jobPosting.isInEnglish
                      ? TEXT.buttonDescriptor.applyEnglish
                      : TEXT.buttonDescriptor.applyWithProjectNumber}
                  </Button>
                </a>
              </div>
            )}
          </CardActions>
        </Card>
      </div>
    );
  }
}

JobPostingDetails.defaultProps = {};

JobPostingDetails.propTypes = {
  projectId: PropTypes.string.isRequired
};

export default JobPostingDetails;
