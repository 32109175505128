import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon
} from 'react-share';

import PrintIcon from '@material-ui/icons/Print';
import { IconButton, SvgIcon } from '@material-ui/core';

class ShareButtonList extends PureComponent {
  render() {
    /**
     * the onPrint function changes the title of the Page because when using window.print()
     * it uses the document.title as the default Name of the pdf here i want the Name to be
     * related to its jobTitle and jobBrand
     *
     * @param {string} jobTitle
     * @param {string} jobBrand
     */
    function onPrint(jobTitle, jobBrand) {
      const oldDocumentTitle = document.title;
      document.title = `${jobTitle} - ${jobBrand}`;
      window.print();
      document.title = oldDocumentTitle;
    }
    return (
      <div className="share-button-list">
        <EmailShareButton url={this.props.url}>
          <EmailIcon round={this.props.isRound} size={this.props.size} />
        </EmailShareButton>

        <FacebookShareButton url={this.props.url}>
          <FacebookIcon round={this.props.isRound} size={this.props.size} />
        </FacebookShareButton>

        <FacebookMessengerShareButton url={this.props.url}>
          <FacebookMessengerIcon round={this.props.isRound} size={this.props.size} />
        </FacebookMessengerShareButton>

        <WhatsappShareButton url={this.props.url}>
          <WhatsappIcon round={this.props.isRound} size={this.props.size} />
        </WhatsappShareButton>

        <LinkedinShareButton url={this.props.url}>
          <LinkedinIcon round={this.props.isRound} size={this.props.size} />
        </LinkedinShareButton>

        <TwitterShareButton url={this.props.url}>
          <TwitterIcon round={this.props.isRound} size={this.props.size} />
        </TwitterShareButton>

        <a href="https://www.instagram.com/hr_consult_group/" target="_blank" rel="noopener noreferrer">
          <img
            style={{ width: this.props.size, height: this.props.size, borderRadius: '100%' }}
            alt="Instagram"
            src="/img/insta_logo.svg"
          />
        </a>

        <RedditShareButton url={this.props.url}>
          <RedditIcon round={this.props.isRound} size={this.props.size} />
        </RedditShareButton>

        <TelegramShareButton url={this.props.url}>
          <TelegramIcon round={this.props.isRound} size={this.props.size} />
        </TelegramShareButton>

        <IconButton onClick={() => navigator.clipboard.writeText(this.props.url)}>
          <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.07 122.88">
              <path
                d="M97.67 20.81l.01.02c3.7.01 7.04 1.51 9.46 3.93 2.4 2.41 3.9 5.74 3.9 9.42h.02v75.31h-.02c-.01 3.68-1.51 7.03-3.93 9.46-2.41 2.4-5.74 3.9-9.42 3.9v.02H38.47v-.02c-3.69-.01-7.04-1.5-9.46-3.93-2.4-2.41-3.9-5.74-3.91-9.42V34.19h.02c.01-3.69 1.52-7.04 3.94-9.46 2.41-2.4 5.73-3.9 9.42-3.91v-.02h.02c19.72.01 39.45.01 59.17.01zM.02 75.38L0 13.39v-.01h.02c.01-3.69 1.52-7.04 3.93-9.46C6.36 1.52 9.69.02 13.37.01V0h59.21c7.69 0 8.9 9.96.01 10.16H13.38v-.02c-.88 0-1.68.37-2.27.97-.59.58-.96 1.4-.96 2.27h.02v61.99c0 8.26-10.15 8.72-10.15.01zm100.89 34.11V34.18h.02c0-.87-.37-1.68-.97-2.27-.59-.58-1.4-.96-2.28-.96v.02H38.46v-.02c-.88 0-1.68.38-2.27.97-.59.58-.96 1.4-.96 2.27h.02v75.31h-.02c0 .88.38 1.68.97 2.27.59.59 1.4.96 2.27.96v-.02h59.22v.02c.87 0 1.68-.38 2.27-.97.59-.58.96-1.4.96-2.27h-.01z"
                fillRule="evenodd"
                clipRule="evenodd"
              />
            </svg>
          </SvgIcon>
        </IconButton>

        <IconButton onClick={() => onPrint(this.props.jobTitle, this.props.jobBrand)}>
          <PrintIcon />
        </IconButton>
      </div>
    );
  }
}

ShareButtonList.defaultProps = {
  size: 45,
  isRound: true
};

ShareButtonList.propTypes = {
  url: PropTypes.string.isRequired,
  size: PropTypes.number,
  isRound: PropTypes.bool,
  jobTitle: PropTypes.string.isRequired,
  jobBrand: PropTypes.string.isRequired
};

export default ShareButtonList;
