import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import $ from 'jquery';
import { URLSearchParams } from '@lvchengbin/url';

import * as CONSTANTS from '../constants';

const systemPartner = CONSTANTS.brand;
const THEME = require('../theme')[systemPartner];

class DataPrivacySettingsComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mobility: 'Keine Angabe',
      desiredRaiseForChange: 'Keine Angabe',
      desiredSalary: 'Keine Angabe',
      motivationForChange: 'Keine Angabe',
      channelForInformation: 'Keine Angabe',
      frequencyOfInformation: 'Keine Angabe',
      isAgreedToStoreForCurrentProject: false,
      isAgreedToStoreForFutureProjects: false,
      isRevocationAcknowledged: false,
      checkedNewsletterAndMarketing: false
    };
  }

  updateCandidatePrivacyDetailsAPICall = () => {
    const url = CONSTANTS.updateCandidatePrivacyDetailsURL;
    const params = new URLSearchParams(window.location.search);
    const guest = params.get('guest');
    const requestBody = {
      candidatePrivacyDetails: this.state,
      guest
    };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(requestBody),
      timeout: 30000
    })
      .done(() => {
        this.props.liftUpStateToMain({ isThankYou: true });
      })
      .fail(err => {
        console.log('Error handling required', err, url);
      });
  };

  handleSwitchChange = name => event => {
    if (name === 'isAgreedToStoreForFutureProjects' && event.target.checked) {
      this.setState({
        isAgreedToStoreForCurrentProject: true,
        isAgreedToStoreForFutureProjects: true
      });
    } else if (name === 'isAgreedToStoreForCurrentProject' && !event.target.checked) {
      this.setState({
        isAgreedToStoreForCurrentProject: false,
        isAgreedToStoreForFutureProjects: false
      });
    } else {
      this.setState({ [name]: event.target.checked });
    }
  };

  handleInputFieldChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleDropDownSelect = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  generateSwitchButton(statePropName) {
    return (
      <span className="pl-3">
        <Switch
          checked={this.state[statePropName]}
          onChange={this.handleSwitchChange(statePropName)}
          color="primary"
          value={statePropName}
        />
        {this.state[statePropName] ? 'Ja' : 'Nein'}
      </span>
    );
  }

  renderSaveSettingsButton(buttonLabel) {
    return (
      <div className="text-right">
        <Button
          variant="contained"
          disabled={!(this.state.isRevocationAcknowledged && this.state.isAgreedToStoreForCurrentProject)}
          color="primary"
          onClick={() => this.updateCandidatePrivacyDetailsAPICall()}
        >
          {buttonLabel}
        </Button>
      </div>
    );
  }

  generateDropDown(valueList, statePropName) {
    const baseList = valueList;
    if (!baseList.includes('Keine Angabe')) baseList.unshift('Keine Angabe');
    return (
      <FormControl margin="normal" className="mt-0">
        <Select
          value={this.state[statePropName]}
          onChange={this.handleDropDownSelect}
          className="ml-3"
          inputProps={{
            name: statePropName
          }}
        >
          {baseList.map(item => (
            <MenuItem
              key={`id_${statePropName}_${item
                .toLowerCase()
                .split(' ')
                .join('_')}`}
              value={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  generateCheckBox(stateVariableName) {
    return <Checkbox checked={this.state[stateVariableName]} onChange={this.handleSwitchChange(stateVariableName)} color="primary" />;
  }

  generateAnchorToFile(pathToFile, linkDescription) {
    return (
      <span>
        <a href={`${pathToFile}`}>{linkDescription}</a>
      </span>
    );
  }

  addItemToArrayStart(baseArr, item) {
    const result = baseArr;
    result.unshift(item);
    return result;
  }

  render() {
    return (
      <div className="container pt-5">
        <Paper className="p-3" elevation={1}>
          <Typography variant="h4" component="h4" className="pb-3">
            Freiwillige Angaben
          </Typography>
          <Typography variant="h6" component="h6" className="pb-2">
            Sind Sie bereit, für einen neuen Job umzuziehen?
            {this.generateDropDown(CONSTANTS.dropDownFields.mobility, 'mobility')}
          </Typography>
          <Typography variant="h6" component="h6" className="pb-2">
            Wieviel Prozent mehr Gehalt möchten Sie bei einem Jobwechsel erhalten?
            {this.generateDropDown(CONSTANTS.dropDownFields.desiredRaiseForChange, 'desiredRaiseForChange')}
          </Typography>
          <Typography variant="h6" component="h6" className="pb-2">
            Wie hoch ist Ihr gewünschtes Jahreszielgehalt?
            {this.generateDropDown(CONSTANTS.dropDownFields.desiredSalary, 'desiredSalary')}
          </Typography>
          <Typography variant="h6" component="h6" className="pb-2">
            Was ist Ihre Motivation für einen Wechsel?
            {this.generateDropDown(CONSTANTS.dropDownFields.motivationForChange, 'motivationForChange')}
          </Typography>
          <Typography variant="h6" component="h6" className="pb-2">
            Wie wollen Sie über neue Karriereangebote informiert werden?
            {this.generateDropDown(CONSTANTS.dropDownFields.channelForInformation, 'channelForInformation')}
          </Typography>
          <Typography variant="h6" component="h6" className="pb-2">
            Wie regelmäßig wollen Sie über die Karriereangebote informiert werden?
            {this.generateDropDown(CONSTANTS.dropDownFields.frequencyOfInformation, 'frequencyOfInformation')}
          </Typography>

          <Typography variant="h4" component="h4" className="pb-3 pt-4">
            Datenschutzbestimmung
          </Typography>

          <Typography variant="h6" component="h6" className="pb-2">
            {this.generateCheckBox('isAgreedToStoreForCurrentProject')}
            Einverständnis Speicherung für aktuelles Projekt {this.generateAnchorToFile(THEME.agbDocument1, '(AGB-Dokument)')}
            {/* {this.generateSwitchButton('isAgreedToStoreForCurrentProject')} */}
          </Typography>
          <Typography variant="h6" component="h6" className="pb-2">
            {this.generateCheckBox('isAgreedToStoreForFutureProjects')}
            Einverständnis Speicherung für zukünftige Projekte {this.generateAnchorToFile(THEME.agbDocument2, '(AGB-Dokument)')}
            {/* {this.generateSwitchButton('isAgreedToStoreForFutureProjects')} */}
          </Typography>
          <Typography variant="h6" component="h6" className="pb-2">
            {this.generateCheckBox('isRevocationAcknowledged')}
            Widerrufsrecht zur Kenntnis genommen {this.generateAnchorToFile(THEME.revocationDocument, '(Widerrufsrecht-Dokument)')}
            {/* {this.generateSwitchButton('isRevocationAcknowledged')} */}
          </Typography>
          <Typography variant="h6" component="h6" className="pb-2">
            {this.generateCheckBox('checkedNewsletterAndMarketing')}
            Newsletter / Marketing
            {/* {this.generateSwitchButton('checkedNewsletterAndMarketing')} */}
          </Typography>
          {this.renderSaveSettingsButton('Einstellungen speichern')}
        </Paper>
      </div>
    );
  }
}

DataPrivacySettingsComponent.propTypes = {
  liftUpStateToMain: PropTypes.func.isRequired
};

export default DataPrivacySettingsComponent;
