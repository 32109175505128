const text = {
  placeholder: {
    fullTextSearch: 'Volltext Suche',
    locationSearch: 'Region/PLZ',
    industrySectorSearch: 'Branche'
  },
  buttonDescriptor: {
    jobPostingSummary: 'Details',
    jobPostingDetails: 'zurück zur hauptseite',
    jobPostingDetailsEnglish: 'back to homepage',
    applyWithProjectNumber: 'bewerben',
    applyEnglish: 'apply'
  }
};

module.exports = text;
