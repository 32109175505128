import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';

// import * as CONSTANTS from '../constants';
//
// const systemPartner = CONSTANTS.partner;
// const THEME = require('../theme')[systemPartner];

class SearchFilterInputField extends PureComponent {
  render() {
    return (
      // TODO Props: https://material-ui.com/api/text-field/
      <TextField
        {...this.props}
        margin="normal"
        fullWidth
        variant="outlined"
        style={{ background: '#fff' }}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
    );
  }
}

SearchFilterInputField.defaultProps = {};

SearchFilterInputField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SearchFilterInputField;
