import React, { PureComponent } from 'react';
import $ from 'jquery';
// import CircularProgress from '@material-ui/core/CircularProgress';

// custom components
import { URLSearchParams } from '@lvchengbin/url';
import * as CONSTANTS from '../constants';
import LinkNotValid from './LinkNotValid';
import ThankYou from './ThankYou';
import DataPrivacySettingsComponent from './DataPrivacySettingsComponent';

class DataPrivacySettings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      response: {},
      isLoading: true,
      isThankYou: false
    };
  }

  componentDidMount() {
    this.validateDataPrivacySettingsUniqueLink();
  }

  liftUpStateToMain = stateUpdate => {
    this.setState(stateUpdate);
  };

  validateDataPrivacySettingsUniqueLink = () => {
    const params = new URLSearchParams(window.location.search);
    const url = CONSTANTS.validateDataPrivacySettingsUniqueLinkURL;
    const parameter = params.get('guest');
    const requestBody = { guest: parameter };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(requestBody),
      timeout: 30000
    })
      .done(responseBody => {
        console.log('DataPrivacySettings.validateDataPrivacySettingsUniqueLink() :: ', responseBody);
        this.setState({ response: responseBody, isLoading: false });
      })
      .fail(err => {
        console.log('Error handling required', err, url);
      });
  };

  saveDataPrivacyAgreementSettings() {
    const params = new URLSearchParams(window.location.search);
    const url = CONSTANTS.updateCandidatePrivacyDetailsURL;
    const requestBody = { guest: params.get('guest') };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(requestBody),
      timeout: 30000
    })
      .done(responseBody => {
        console.log('DataPrivacySettings.saveDataPrivacyAgreementSettings() :: ', responseBody);
      })
      .fail(err => {
        console.log('Error handling required', err, url);
      });
  }

  // WARNING use enum in state
  render() {
    return (
      <div>
        {this.state.isThankYou && <ThankYou />}
        {this.state.response.isValid && !this.state.isLoading && !this.state.isThankYou && (
          <DataPrivacySettingsComponent liftUpStateToMain={this.liftUpStateToMain} />
        )}
        {!this.state.response.isValid && !this.state.isLoading && !this.state.isThankYou && <LinkNotValid />}
      </div>
    );
  }
}

export default DataPrivacySettings;
