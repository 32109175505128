// WARNING: no !important in css

const theme = {
  personaltotal: {
    homepage: 'https://www.personal-total.de/',
    logo: '/img/pt_logo.png',
    logoColor: '/img/pt_logo_color.png',
    logoAlt: 'PersonalTotal',
    background: '/img/pt_background.jpg',
    agbDocument1: '/file/Einverständnis Speicherung aktuelles Projekt Personal Total.pdf',
    agbDocument2: '/file/Einverständnis Speicherung zukünftige Projekte Personal Total.pdf',
    revocationDocument: '/file/Widerrufsrecht_Personaltotal.pdf',
    appBar: {
      background: '#a7111b'
    },
    baseColor: '#a7111b',
    imprintLink: 'https://www.personal-total.de/ueber-personal-total/Impressum-Personal-Total',
    privacyLink: 'https://www.personal-total.de/ueber-personal-total/Datenschutzerklaerung-Personal-Total'
  },
  senator: {
    homepage: 'https://www.senator-partners.de/',
    logo: '/img/senator_logo.png',
    logoAlt: 'Senator',
    background: '/img/senator_background.jpg',
    agbDocument1: '/file/Einverständnis Speicherung aktuelles Projekt Senator.pdf',
    agbDocument2: '/file/Einverständnis Speicherung zukünftige Projekte Senator.pdf',
    revocationDocument: '/file/Widerrufsrecht_Senator.pdf',
    appBar: {
      background: '#fff'
    },
    baseColor: '#525d65',
    imprintLink: 'https://www.senator-partners.de/impressum',
    privacyLink: 'https://www.senator-partners.de/datenschutzerklaerung'
  },
  matchflix: {
    homepage: 'https://www.match-flix.de/',
    logo: '/img/mf_logo.png',
    logoAlt: 'Match-Flix',
    background: '/img/mf_background.jpg',
    agbDocument1: '/file/Einverständnis Speicherung aktuelles Projekt Match-Flix.pdf',
    agbDocument2: '/file/Einverständnis Speicherung zukünftige Projekte Match-Flix.pdf',
    revocationDocument: '/file/Widerrufsrecht_Match-Flix.pdf',
    appBar: {
      background: '#000'
    },
    baseColor: '#39a9dc',
    imprintLink: 'https://app.match-flix.de/imprint',
    privacyLink: 'https://app.match-flix.de/privacy'
  },
  hrcg: {
    homepage: 'https://www.hr-consult-group.de/',
    logo: '/img/hr_logo.png',
    logoAlt: 'HRCG',
    background: '/img/hrnet_background.jpg',
    agbDocument1: '',
    agbDocument2: '',
    revocationDocument: '',
    appBar: {
      background: '#DEDDD8'
    },
    baseColor: '#DEDDD8',
    imprintLink: 'https://www.hr-consult-group.de/impressum',
    privacyLink: 'https://www.hr-consult-group.de/datenschutz'
  },
  lienert: {
    homepage: 'https://www.hr-consult-group.de/',
    logo: '/img/lienert_logo.png',
    logoAlt: 'Lienert',
    background: '/img/lienert_background.jpg',
    agbDocument1: '',
    agbDocument2: '',
    revocationDocument: '',
    appBar: {
      background: '#DEDDD8'
    },
    baseColor: '#092455',
    imprintLink: '',
    privacyLink: ''
  }
};

module.exports = theme;
