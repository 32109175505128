import React, { PureComponent } from 'react';
import './LinkNotValid.css';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

class ThankYou extends PureComponent {
  render() {
    return (
      <div className="container pt-5" key="maincontainer" style={{ 'min-height': '84vh' }}>
        <Paper className="p-5">
          <div className="row h-100">
            <div className="col text-center">
              <Typography variant="display1" gutterBottom>
                Vielen Dank! Ihre Einstellungen wurden gespeichert.
              </Typography>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export default ThankYou;
