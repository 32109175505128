import React, { PureComponent } from 'react';
import $ from 'jquery';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

// custom components
import CircularProgress from '@material-ui/core/CircularProgress';
import * as CONSTANTS from './constants';
import JobPostingList from './components/JobPostingList';
import JobPostingDetails from './components/JobPostingDetails';
import DataPrivacySettingsMain from './dataPrivacySettings/DataPrivacySettingsMain';
// custom styling
import './App.css';

const systemPartner = CONSTANTS.brand;
const THEME = require('./theme')[systemPartner];

// WARNING: for testing only
// import mockdata from './tmp/mockdata';

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      jobPostingList: [],
      jobPostingListJoin: [],
      redirectToPosting: false,
      partnerId: '',
      searchQuery: '',
      showLoadingIndicator: false,
      lastLocationFilterValue: '',
      lastIndustrySectorFilterValue: ''
    };
  }

  liftUpStateToApp = (stateProp, value) => {
    this.setState({ [stateProp]: value });
  };

  getQueryParameter(key) {
    const cleanKey = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, '\\$&'); // eslint-disable-line
    const match = window.location.search.match(new RegExp(`[?&]${cleanKey}=([^&]+)(&|$)`));
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

  getJobPostingList() {
    const url = CONSTANTS.jobPostingListURL;
    const partnerId = this.getQueryParameter('pn');
    const payload = { brand: CONSTANTS.brand, partnerId };
    this.setState({ partnerId });

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(payload),
        timeout: 30000
      })
        .done((responseBody, status) => {
          console.log('API /getJobPostingList status response', status);
          this.setState({ jobPostingList: responseBody });
          resolve();
        })
        .fail(err => {
          console.log('Error handling required', err);
          reject();
        });
    });
  }

  // load jobPostingList and create filtered list of it (only MultiJoin postings)
  getJobPostingListAndFilter = () =>
    new Promise((resolve, reject) => {
      this.getJobPostingList()
        .then(() => {
          const joinList = this.state.jobPostingList.filter(jobPosting => jobPosting.isOnlineJoin);
          this.setState(prevState => ({ ...prevState, jobPostingListJoin: joinList }));
          resolve();
        })
        .catch(() => {
          reject();
        });
    });

  componentDidMount() {
    this.getJobPostingListAndFilter();
    const tagManagerArgs = {
      gtmId: 'GTM-PSNL3TQ9'
    };
    TagManager.initialize(tagManagerArgs);

    if (CONSTANTS.isMatchflix) {
      ReactGA.initialize('UA-133568644-2');
      ReactGA.ga('set', 'anonymizeIp', true);
    }
  }

  renderRoutes() {
    const result = [];

    this.state.jobPostingList.forEach(jobItem => {
      result.push(
        <Route
          key={jobItem.projectId}
          exact
          path={`/${jobItem.projectNumber}`}
          render={props => <JobPostingDetails {...props} projectId={jobItem.projectId} />}
        />
      );
    });
    return result;
  }

  render() {
    let redirect = '';
    if (this.state.redirectToPosting && this.state.jobPostingList.length > 0) {
      this.setState({ redirectToPosting: false });
      redirect = <Redirect to={`/${this.state.redirectToPosting}`} />;
    }

    return (
      <div
        className={`ctm-bkg-${systemPartner}`}
        id="container-body"
        style={{
          background: `url(${THEME.background}) no-repeat center center fixed`,

          backgroundSize: 'cover'
        }}
      >
        <AppBar className="p-4" style={THEME.appBar} id="header">
          <div className="text-center">
            <a href={THEME.homepage}>
              <img
                src={THEME.logo}
                alt={THEME.logoAlt}
                className="headerLogo"
                style={{
                  maxHeight: '48px'
                }}
              />
            </a>
          </div>
        </AppBar>
        <div id="body">
          <Router>
            <React.Fragment>
              {this.state.showLoadingIndicator && (
                <div id="loading-indicator-overlay">
                  <div id="loading-indicator-container">
                    <CircularProgress className="mb-3" size={60} thickness={5} />
                  </div>
                </div>
              )}
              {redirect}
              <Route exact path="/dataprivacysettings" render={() => <DataPrivacySettingsMain />} />
              {CONSTANTS.isHrcg && (
                <Route
                  exact
                  path="/join"
                  render={props => (
                    <JobPostingList
                      getJobPostingList={() => this.getJobPostingListAndFilter()}
                      jobPostingList={this.state.jobPostingListJoin}
                      {...props}
                    />
                  )}
                />
              )}
              <Route
                exact
                path="/"
                render={props => (
                  <JobPostingList
                    {...props}
                    getJobPostingList={() => this.getJobPostingList()}
                    jobPostingList={this.state.jobPostingList}
                    liftUpStateToApp={this.liftUpStateToApp}
                    partnerId={this.state.partnerId}
                    searchQuery={this.state.searchQuery}
                    lastLocationFilterValue={this.state.lastLocationFilterValue}
                    lastIndustrySectorFilterValue={this.state.lastIndustrySectorFilterValue}
                  />
                )}
              />
              {this.renderRoutes()}
            </React.Fragment>
          </Router>
        </div>
        <footer id="footer" className="page-footer font-small">
          <div className="footer-copyright text-center py-3">
            <a href={THEME.imprintLink} className="p-3" style={systemPartner === 'matchflix' ? { color: '#000' } : { color: '#fff' }}>
              Impressum
            </a>
            <a href={THEME.privacyLink} className="p-3" style={systemPartner === 'matchflix' ? { color: '#000' } : { color: '#fff' }}>
              Datenschutz
            </a>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
