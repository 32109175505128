import React, { PureComponent } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

// import AppBar from '@material-ui/core/AppBar';
// import Typography from '@material-ui/core/Typography';
import debounce from 'lodash/debounce';
import flowRight from 'lodash/flowRight';
// custom components
import SearchFilterInputField from './SearchFilterInputField';
import JobPostingSummary from './JobPostingSummary';
import * as CONSTANTS from '../constants';
import * as TEXT from './staticResources/text';

class JobPostingList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filteredJobPostingList: [],
      isSorted: false
    };
    this.onChangeFullTextSearch = flowRight(debounce(this.onChangeFullTextSearch, 200), e => e.persist() || e);
  }

  componentDidMount() {
    if (CONSTANTS.isMatchflix) ReactGA.pageview(window.location.pathname + window.location.search);
  }

  getJobPostingListBySearchTerm(value) {
    const url = CONSTANTS.jobPostingListBySearchTermURL;
    const payload = {
      query: value,
      brand: CONSTANTS.brand,
      partnerId: this.props.partnerId
    };
    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(payload),
        timeout: 30000
      })
        .done((responseBody, status) => {
          console.log('API /getJobPostingListBySearchTerm status response', status);
          console.log('API /getJobPostingListBySearchTerm response', responseBody, status);
          this.props.liftUpStateToApp('jobPostingList', responseBody);
          this.setState({ filteredJobPostingList: [], isSorted: false });
          resolve();
        })
        .fail(err => {
          console.log('Error handling required', err);
          reject();
        });
    });
  }

  sortByLocation = location => {
    let result;

    result = this.props.jobPostingList.filter(
      item =>
        (item.jobPosting.location &&
          item.jobPosting.location
            .toLowerCase()
            .trim()
            .includes(location)) ||
        (item.jobPosting.postalCode &&
          item.jobPosting.postalCode
            .toLowerCase()
            .trim()
            .includes(location)) ||
        !location
    );

    // filter for industrySector if something is in the inputField
    if (this.props.lastIndustrySectorFilterValue) {
      result = result.filter(item => {
        console.log(item.industrySector, 'itemIndustry');
        return (
          item.industrySector &&
          item.industrySector
            .toLowerCase()
            .trim()
            .includes(this.props.lastIndustrySectorFilterValue)
        );
      });
    }

    this.props.liftUpStateToApp('lastLocationFilterValue', location);
    this.setState({
      filteredJobPostingList: result,
      isSorted: true
    });
  };

  onChangeSortByLocation = e => {
    const location = e.target.value.toLowerCase().trim();
    this.sortByLocation(location);
  };

  sortByIndustrySector = industrySector => {
    let result;
    result = this.props.jobPostingList.filter(
      item =>
        (item.industrySector &&
          item.industrySector
            .toLowerCase()
            .trim()
            .includes(industrySector)) ||
        !industrySector
    );

    // filter for Location if something is in the inputField
    if (this.props.lastLocationFilterValue) {
      result = result.filter(
        item =>
          (item.jobPosting.location &&
            item.jobPosting.location
              .toLowerCase()
              .trim()
              .includes(this.props.lastLocationFilterValue)) ||
          (item.jobPosting.postalCode &&
            item.jobPosting.postalCode
              .toLowerCase()
              .trim()
              .includes(this.props.lastLocationFilterValue))
      );
    }

    this.props.liftUpStateToApp('lastIndustrySectorFilterValue', industrySector);
    this.setState({
      filteredJobPostingList: result,
      isSorted: true
    });
  };

  onChangeSortByIndustrySector = e => {
    const industrySector = e.target.value.toLowerCase().trim();
    this.sortByIndustrySector(industrySector);
  };

  onChangeFullText = e => {
    /**
     * because backend doesnt handle it right when calling getJobPostingListBySearchTerm() with an empty String
     * -> thats why getJobPostingList() is used here
     *  */
    if (e.target.value.length === 0) {
      this.props.getJobPostingList().then(() => {
        this.setState({ isSorted: false });
        if (this.props.lastIndustrySectorFilterValue) {
          this.sortByIndustrySector(this.props.lastIndustrySectorFilterValue);
        }
        if (this.props.lastLocationFilterValue) {
          this.sortByLocation(this.props.lastLocationFilterValue);
        }
      });

      this.props.liftUpStateToApp('searchQuery', e.target.value);
      return;
    }

    // minimum query length in Backend is 2 and Maximum 9
    if (e.target.value.length < 2 || e.target.value.length > 8) {
      this.props.liftUpStateToApp('searchQuery', e.target.value);
      return;
    }
    this.onChangeFullTextSearch(e);
    this.props.liftUpStateToApp('searchQuery', e.target.value);
  };

  onChangeFullTextSearch = e => {
    this.getJobPostingListBySearchTerm(e.target.value).then(() => {
      if (this.props.lastIndustrySectorFilterValue) {
        this.sortByIndustrySector(this.props.lastIndustrySectorFilterValue);
      }
      if (this.props.lastLocationFilterValue) {
        this.sortByLocation(this.props.lastLocationFilterValue);
      }
    });
  };

  renderPostingCount() {
    if (this.state.filteredJobPostingList.length > 0 || this.props.lastLocationFilterValue || this.props.lastIndustrySectorFilterValue) {
      return this.state.filteredJobPostingList.length;
    }
    return this.props.jobPostingList.length;
  }

  renderSearchFilterFieldGroup(jobPostingCount) {
    return (
      <div className="container pb-5">
        <div className="row">
          <div className="col-12 pt-2">
            <h2 className="text-center text-white">{jobPostingCount} Jobs</h2>
          </div>
          <div className="col-12 col-md-4">
            <SearchFilterInputField
              placeholder={TEXT.placeholder.fullTextSearch}
              onChange={this.onChangeFullText}
              value={this.props.searchQuery}
            />
          </div>
          <div className="col-12 col-md-4">
            <SearchFilterInputField placeholder={TEXT.placeholder.locationSearch} onChange={this.onChangeSortByLocation} />
          </div>
          <div className="col-12 col-md-4">
            <SearchFilterInputField placeholder={TEXT.placeholder.industrySectorSearch} onChange={this.onChangeSortByIndustrySector} />
          </div>
        </div>
      </div>
    );
  }

  renderJobPostingList() {
    let jobPostingList = this.props.jobPostingList;
    if (this.state.isSorted) {
      jobPostingList = this.state.filteredJobPostingList;
    }
    const result = [];
    jobPostingList.forEach(item => {
      result.push(
        <JobPostingSummary
          key={item.projectId}
          headline={!item.jobPosting.title ? 'N/A' : item.jobPosting.title}
          subtitle={item.jobPosting.location}
          // project brand is maybe undefined, items.brand doesnt exist after filtering
          projectBrand={item.brand}
          projectNumber={item.projectNumber}
          buttonDescription={TEXT.buttonDescriptor.jobPostingSummary}
        />
      );
    });
    return result;
  }

  render() {
    return (
      <div className="JobPostingList">
        {this.renderSearchFilterFieldGroup(this.renderPostingCount())}
        <div className="container">{this.renderJobPostingList()}</div>
      </div>
    );
  }
}

JobPostingList.defaultProps = {
  jobPostingList: [],
  partnerId: '',
  lastLocationFilterValue: '',
  lastIndustrySectorFilterValue: ''
};

JobPostingList.propTypes = {
  jobPostingList: PropTypes.arrayOf(PropTypes.object),
  liftUpStateToApp: PropTypes.func.isRequired,
  partnerId: PropTypes.string,
  searchQuery: PropTypes.string.isRequired,
  getJobPostingList: PropTypes.func.isRequired,
  lastLocationFilterValue: PropTypes.string,
  lastIndustrySectorFilterValue: PropTypes.string
};

export default JobPostingList;
