import React, { PureComponent } from 'react';
import './LinkNotValid.css';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

class LinkNotValid extends PureComponent {
  render() {
    return (
      <div className="container pt-5" key="maincontainer" style={{ 'min-height': '84vh' }}>
        <Paper className="p-3">
          <div className="row h-100">
            <div className="col text-center">
              <Typography variant="display3" gutterBottom>
                Zugriff verweigert
              </Typography>
              <h3>
                Ihre Daten wurden bereits gespeichert. Sollten Sie Fragen oder Änderungen haben wenden Sie sich bitte an{' '}
                <a href="mailto:info@hr-consult-group.de">info@hr-consult-group.de</a>.
              </h3>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export default LinkNotValid;
