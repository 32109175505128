module.exports = {
  getTitle(brand) {
    let title;
    switch (brand) {
      case 'lienert':
        title = 'Lienert';
        break;
      case 'personaltotal':
        title = 'Personal Total';
        break;
      case 'senator':
        title = 'Senator';
        break;
      case 'matchflix':
        title = 'Match-Flix';
        break;
      case 'hrcg':
        title = 'HR Consult Group';
        break;
      default:
    }
    return title;
  },

  getApplicationUrl(brand) {
    let url;
    switch (brand) {
      case 'interim':
        url = 'https://profile.interim.senator-partners.de/register?jobNumber=';
        break;
      case 'personaltotal':
        url = 'https://profile.personal-total.de/register?jobNumber=';
        break;
      case 'senator':
        url = 'https://profile.senator-partners.de/register?jobNumber=';
        break;
      case 'matchflix':
        url = 'https://profile.match-flix.de/register?jobNumber=';
        break;
      default:
    }
    return url;
  },

  getGoogleTagManagerScriptHead(brand) {
    let script;
    switch (brand) {
      case 'personaltotal':
        script =
          "<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PBWD5ND');</script>";
        break;
      case 'senator':
        script =
          "<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MJHGQ77');</script>";
        break;
      case 'hrcg':
        script =
          "<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WX28JG3');</script>";
        break;
      default:
    }
    return script;
  },

  getGoogleTagManagerScriptBody(brand) {
    let script;
    switch (brand) {
      case 'personaltotal':
        script =
          '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PBWD5ND" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>';
        break;
      case 'senator':
        script =
          '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MJHGQ77" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>';
        break;
      case 'hrcg':
        script =
          '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WX28JG3" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>';
        break;
      default:
    }
    return script;
  },

  getOgMetaTags(brand) {
    let metaTags;

    switch (brand) {
      case 'personaltotal':
        metaTags = /* html */ `<meta property="og:image" content="img/pt_background.jpg"></meta>`;
        break;
      case 'senator':
        metaTags = /* html */ `<meta property="og:image" content="img/senator_background.jpg"></meta>`;
        break;
      case 'hrcg':
        metaTags = /* html */ `<meta property="og:image" content="img/hrnet_background.jpg"></meta>`;
        break;
      default:
    }
    return metaTags;
  }
};
