import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as CONSTANTS from '../constants';
import * as THEME from '../theme';

class JobPostingSummary extends PureComponent {
  render() {
    const systemPartner = CONSTANTS.brand;
    const projectBrandLogo =
      this.props.projectBrand === 'personaltotal' ? THEME[this.props.projectBrand].logoColor : THEME[this.props.projectBrand].logo;
    return (
      <div className="mb-2">
        <Card>
          <div className="container pl-0">
            <div className="row">
              <div className="col-10">
                <CardContent>
                  <Typography variant="headline">{this.props.headline}</Typography>
                  <Typography variant="subtitle1">{this.props.subtitle}</Typography>
                </CardContent>
              </div>
              {systemPartner === 'hrcg' && (
                <div className="col-2 mt-4">
                  <CardMedia component="img" alt="logo" image={projectBrandLogo} title="logo" />
                </div>
              )}
            </div>
            <CardActions>
              <Link to={`/${this.props.projectNumber}`}>
                <Button Link={`/${this.props.projectNumber}`} size="medium">
                  {this.props.buttonDescription}
                </Button>
              </Link>
            </CardActions>
          </div>
        </Card>
      </div>
    );
  }
}

JobPostingSummary.defaultProps = {
  subtitle: '',
  buttonDescription: 'details'
};

JobPostingSummary.propTypes = {
  projectNumber: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  buttonDescription: PropTypes.string,
  projectBrand: PropTypes.string.isRequired
};

export default JobPostingSummary;
